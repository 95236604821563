<template>
  <div v-if="isAuthenticated" class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo" />

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay" v-bind:breadcrumbs="breadcrumbs" v-bind:title="pageTitle" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div v-if="userData" class="d-flex flex-column-fluid">
            <div :class="{ 'container-fluid': contentFluid, container: !contentFluid }">
              <b-card v-if="isCoordinator" class="card-custom">
                <b-row>
                  <b-col md="6" lg="">
                    <div class="d-md-flex">
                      <div class="flex-shrink-0 mb-5 mb-md-0 mr-md-7">
                        <cs-avatar
                          classes="symbol symbol-50 symbol-lg-100 font-size-h3 symbol-label font-weight-boldest"
                          :src="get(userData, 'avatar')"
                          :name="get(userData, 'first_name') + ' ' + get(userData, 'last_name')"
                          divided=" "
                          :initial-letters="2"
                        ></cs-avatar>
                      </div>
                      <div class="flex-grow-1">
                        <div class="mt-2">
                          <div class="text-dark font-size-h5 font-weight-bold">
                            Account Information
                          </div>
                          <div class="d-flex flex-wrap my-2">
                            <a v-if="userData.email" href="#" class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                              <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                <inline-svg src="media/svg/icons/Communication/Mail-notification.svg" />
                              </span>
                              {{ userData.email }}
                            </a>
                            <a v-if="userData.phone" href="#" class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                              <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                <inline-svg src="media/svg/icons/Communication/Active-call.svg" />
                              </span>
                              {{ userData.phone }}
                            </a>
                          </div>
                        </div>
                        <div class="font-weight-bold text-dark-50 py-2 py-lg-2">
                          {{ userData.street }}
                          <br />
                          {{ userData.suite }}, {{ userData.city }}
                          <br />
                          {{ userData.state }}, {{ userData.zip }}
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6" lg="">
                    <div class="mt-2">
                      <div class="text-dark font-size-h5 font-weight-bold">
                        Bank Information
                      </div>
                    </div>
                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2">
                      {{ userData.bank_name }}, {{ userData.bank_street_address }}
                      <br />
                      {{ userData.bank_suite }}, {{ userData.bank_city }}
                      <br />
                      {{ userData.bank_state }}, {{ userData.bank_zip }}
                    </div>
                  </b-col>
                </b-row>
              </b-card>

              <b-nav class="nav-custom">
                <template v-if="isCoordinator">
                  <router-link to="/profile/personal-information" v-slot="{ href, navigate, isExactActive }">
                    <b-nav-item :class="[isExactActive && 'active']" :href="href" @click="navigate">
                      <h4 class="nav-item__title mb-0">Personal Information</h4>
                    </b-nav-item>
                  </router-link>
                  <router-link to="/profile/banking-details" v-slot="{ href, navigate, isExactActive }">
                    <b-nav-item :class="[isExactActive && 'active']" :href="href" @click="navigate">
                      <h4 class="nav-item__title mb-0">Banking Details</h4>
                    </b-nav-item>
                  </router-link>
                </template>
                <router-link to="/profile/transactions" v-slot="{ href, navigate, isExactActive }">
                  <b-nav-item :class="[isExactActive && 'active']" :href="href" @click="navigate">
                    <h4 class="nav-item__title mb-0">Transactions</h4>
                  </b-nav-item>
                </router-link>
                <router-link to="/profile/settings" v-slot="{ href, navigate, isExactActive }">
                  <b-nav-item :class="[isExactActive && 'active']" :href="href" @click="navigate">
                    <h4 class="nav-item__title mb-0">Reset Password</h4>
                  </b-nav-item>
                </router-link>
              </b-nav>

              <transition name="fade-in">
                <router-view v-if="userData" />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
    <cs-notification position="bottom left" group="main"> </cs-notification>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KTAside from '@/components/blocks/Aside';
import KTHeader from '@/components/blocks/Header';
import KTHeaderMobile from '@/components/blocks/HeaderMobile';
import KTFooter from '@/components/blocks/Footer';
import HtmlClass from '@/helpers/htmlclass.service';
import KTSubheader from '@/components/blocks/Subheader';
import KTScrollTop from '@/components/generic/ScrollTop';
import Loader from '@/components/generic/Loader';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/store/modules/htmlclass.js';
import CsNotification from '@/components/alerts/CsNotification';

export default {
  name: 'ProfileLayout',
  components: {
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTScrollTop,
    Loader,
    CsNotification,
  },
  data() {
    return {
      avatar: null,
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    const name = [this.userData.first_name || '', this.userData.last_name || ''].filter((e) => e != '');
    this.$root.$emit('changeTitle', name.join(' ') || this.userData.email);

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 2000);
  },
  methods: {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      breadcrumbs: 'breadcrumbs/breadcrumbs',
      pageTitle: 'breadcrumbs/pageTitle',
      layoutConfig: 'config/layoutConfig',
    }),

    userData() {
      return this.$store.state.auth.user;
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    },
  },
};
</script>
